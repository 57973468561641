import React, { useState } from "react";
import { formatdate } from "../common/functions";
import SelFilter from "./selFilter.component";
import AuthService from "../services/auth.service";

import Box from '@mui/material/Box';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
    DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';


import { GrEdit, GrTrash } from 'react-icons/gr';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { GridCellExpand } from "../common/renderCellExpand";


import * as constants from '../common/constants'

import UserService from "../services/user.service";

import { useSnackbar } from 'notistack';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function Settings() {
    const { enqueueSnackbar } = useSnackbar();
    const [newDefProject, setNewDefProject] = useState('TEST');
    const [newDefBlock, setNewDefBlock] = useState('ITM');
    const [newDefLayout, setNewDefLayout] = useState('Default');
    const [newDefAPI, setNewDefAPI] = useState('MMS200MI');
    const [newDefTran, setNewDefTran] = useState('CpyItmWhs');
    const [formError, setFormError] = useState(false);

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogUserOpen, setDialogUserOpen] = React.useState(false);
    const [dialogNewProjectOpen, setDialogNewProjectOpen] = React.useState(false);
    const [dialogNewBlockOpen, setDialogNewBlockOpen] = React.useState(false);
    const [dialogUserEditOpen, setDialogUserEditOpen] = React.useState(false);
    const [dialogUserDeleteOpen, setDialogUserDeleteOpen] = React.useState(false);
    const [dialogCopyProjectOpen, setDialogCopyProjectOpen] = React.useState(false);
    const [dialogAppSettingEditOpen, setDialogAppSettingEditOpen] = React.useState(false);
    const [userToEdit, setUserToEdit] = React.useState([]);
    const [appSettingToEdit, setAppSettingToEdit] = React.useState([]);
    const [userToDelete, setUserToDelete] = React.useState([]);
    const [formUserError, setFormUserError] = useState(false);
    const [formUserEditError, setFormUserEditError] = useState(false);
    const [formAppSettingEditError, setFormAppSettingEditError] = useState(false);

    const [newProjectName, setNewProjectName] = React.useState("");
    const [newBlockName, setNewBlockName] = React.useState("");

    const [userLogin, setUserLogin] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userType, setUserType] = useState(constants.USER_TYPE_LOCAL);

    const [allUsers, setAllUsers] = useState([]);
    const [allRoles, setAllRoles] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [allBlocks, setAllBlockss] = useState([]);
    const [allAppSettings, setAllAppSettings] = useState([]);

    const [copyRowData, setCopyRowData] = React.useState([]);
    const [copyFilterDef, setCopyFilterDef] = React.useState({});
    const [targetProject, setTargetProject] = useState('');
    const [sourceProject, setSourceProject] = useState('');


    const columnsCopyProject = [
        { field: 'id', headerName: 'Id' },
        { field: 'project', headerName: 'Project', width: '70' },
        { field: 'block', headerName: 'Block', width: AuthService.getAppSetting(constants.SETTING_COLWIDTHBLOCK)?.value ?? '60',
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        { field: 'table', headerName: 'Table', width: '190' },
        { field: 'layout', headerName: 'Layout', width: '70' },
        {
            field: 'status',
            headerName: 'Status',
            width: '110',
            renderCell: (params) => {
                let status = "";
                status = constants.STATUSES.find(x => x.id === params.row.status)?.val;
                return status;
            },

        },
    ]

    const columnsProjectsAndBlocks = [
        { field: 'name', headerName: 'Name', width: '120' }
    ]

    const columnsUser = [
        // { field: 'id', headerName: 'Id' },
        { field: 'id', headerName: 'Id' },
        { field: 'login', headerName: 'Login', width: '150' },
        { field: 'email', headerName: 'email', width: '300' },
        { field: 'userType', headerName: 'User type', width: '100' },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            width: '80',
            renderCell: (params) => {
                const onClickEdit = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    setDialogUserEditOpen(true);
                    setUserToEdit(params.row);
                    setSelectedRoles([]);
                    setAvailableRoles([]);
                    setChecked([]);
                    UserService.getUser(params.row.id).then(
                        response => {
                            setSelectedRoles(response.data.roles);
                            setAvailableRoles(not(allRoles, response.data.roles));
                        },
                        error => {
                            const resMessage =
                                (error.response.data.errorMessage) ||
                                error.message ||
                                error.toString();
                            enqueueSnackbar(resMessage, { variant: 'error' });
                        }
                    );

                };

                const onClickDelete = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    setUserToDelete(params.row);
                    setDialogUserDeleteOpen(true);
                };

                return <span>
                    <IconButton sx={{ pt:0.1 }} size="small" onClick={onClickEdit}><GrEdit /></IconButton>
                    &nbsp;
                    <IconButton sx={{ pt:0.1 }} size="small" onClick={onClickDelete}><GrTrash /></IconButton>
                </span>;
            },
        },
        {
            field: 'modifiedAt',
            headerName: 'Last change',
            width: '150',
            renderCell: (params) => {
                return formatdate(params.row.modifiedAt);
            }
        },
        { field: 'modfiedBy', headerName: 'Last change by', width: '120' },

    ];

    const columnsAppSetting = [
        // { field: 'id', headerName: 'Id' },
        { field: 'id', headerName: 'Id' },
        { field: 'name', headerName: 'Name', width: '150' },
        { field: 'value', headerName: 'Value', width: '150' },
        { field: 'description', headerName: 'Description', width: '400' },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            width: '80',
            renderCell: (params) => {
                const onClickEdit = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    setDialogAppSettingEditOpen(true);
                    setAppSettingToEdit(params.row);
                };

                return <span>
                    <IconButton sx={{ pt:0.1 }} size="small" onClick={onClickEdit}><GrEdit /></IconButton>
                </span>;
            },
        },
        {
            field: 'modifiedAt',
            headerName: 'Last change',
            width: '150',
            renderCell: (params) => {
                return formatdate(params.row.modifiedAt);
            }
        },
        { field: 'modfiedBy', headerName: 'Last change by', width: '120' },

    ];

    const refreshUsers = () => {
        UserService.getAllUsers().then(
            response => {
                setAllUsers(response.data.sort((a, b) => {
                    const nameA = a.login.toUpperCase();
                    const nameB = b.login.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                }));
            },
            error => {
                setAllUsers([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    }

    const refreshAppSettings = () => {
        UserService.getAllAppSettings().then(
            response => {
                setAllAppSettings(response.data.sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                }));
            },
            error => {
                setAllAppSettings([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    }

    const refreshProjectsAndBlocks = () => {
        UserService.getProjectsAndBlocks().then(
            response => {
                setAllProjects(response.data.projects.map(x => { return { 'id': x, 'name': x } }));
                setAllBlockss(response.data.blocks.map(x => { return { 'id': x, 'name': x } }));
            },
            error => {
                setAllProjects([]);
                setAllBlockss([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    }

    React.useEffect(() => {
        // Runs after the first render() lifecycle
        refreshUsers();

        refreshAppSettings();

        refreshProjectsAndBlocks();

        UserService.getAllRoles().then(
            response => {
                let tmpRoles = response.data.sort((a, b) => {
                    const nameA = a.role.toUpperCase();
                    const nameB = b.role.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
                setAllRoles(tmpRoles.map((value) => {
                    return (
                        value.role
                    )
                }));

            },
            error => {
                setAllRoles([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );

    }, []);

    const handleNewDefClick = (event) => {
        if (newDefLayout.length === 0 || newDefAPI.length === 0 || newDefTran.length === 0) {
            setFormError(true);
        } else {
            setFormError(false);
            setDialogOpen(true);

        }

    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const handleCloseUserDialog = () => {
        setDialogUserOpen(false);
    }

    const handleCloseUserEditDialog = () => {
        setDialogUserEditOpen(false);
    }

    const handleCloseAppSettingEditDialog = () => {
        setDialogAppSettingEditOpen(false);
    }

    const handleSaveAppSettingEditDialog = () => {
        let appSetting = {
            ...appSettingToEdit
        };
        appSetting.id = null;
        UserService.updateAppSetting(appSettingToEdit.id, appSetting).then(resp => {
            enqueueSnackbar('Successfully updated ' + appSetting.name, { variant: 'success' });
            refreshAppSettings();
        },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });

            });
        setDialogAppSettingEditOpen(false);
    }

    const handleSaveUserEditDialog = () => {
        let user = {
            ...userToEdit
        };
        user.roles = selectedRoles;
        user.id = null;
        delete user.password;
        UserService.updateUser(userToEdit.id, user).then(resp => {
            enqueueSnackbar('Successfully updated ' + user.login, { variant: 'success' });
            refreshUsers();
        },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });

            });
        setDialogUserEditOpen(false);
    }

    const handleSaveNewProjectDialog = () => {
        UserService.createProject({ "name": newProjectName, "description": newProjectName }).then(resp => {
            enqueueSnackbar('Successfully created ' + newProjectName, { variant: 'success' });
            refreshProjectsAndBlocks();
        },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });

            });
        setDialogNewProjectOpen(false);
    }

    const handleSaveNewBlockDialog = () => {
        UserService.createBlock({ "name": newBlockName, "description": newBlockName }).then(resp => {
            enqueueSnackbar('Successfully created ' + newBlockName, { variant: 'success' });
            refreshProjectsAndBlocks();
        },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });

            });
        setDialogNewBlockOpen(false);
    }

    const handleUserNewClick = () => {
        setUserLogin("");
        setUserEmail("");
        setUserPassword("");
        setUserToEdit([]);
        setDialogUserOpen(true);
    }

    const handleUserSave = () => {
        let user = {
            ...userToEdit
        };
        user.login = userLogin;
        user.email = userEmail;
        user.password = userType === constants.USER_TYPE_LOCAL ? userPassword : "";
        user.userType = userType;

        UserService.createUser(user).then(resp => {
            enqueueSnackbar('Successfully created ' + userLogin, { variant: 'success' });
            refreshUsers();
        },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });

            })
        setDialogUserOpen(false);
    }

    const createNewDefiniton = () => {
        enqueueSnackbar('Fetching APi definition', { variant: 'info' });
        let options = {
            "project": newDefProject,
            "block": newDefBlock,
            "layout": newDefLayout,
            "api": newDefAPI,
            "transaction": newDefTran
        };
        UserService.createDefinition(options).then(resp => {
            enqueueSnackbar('Successfully created', { variant: 'success' });
        },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });

            })
        setDialogOpen(false);

    }

    const [checked, setChecked] = React.useState([]);
    const [selectedRoles, setSelectedRoles] = React.useState([]);
    const [availableRoles, setAvailableRoles] = React.useState([]);

    const selectedChecked = intersection(checked, selectedRoles);
    const availableChecked = intersection(checked, availableRoles);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleCheckedAvailable = () => {
        setAvailableRoles(availableRoles.concat(selectedChecked));
        setSelectedRoles(not(selectedRoles, selectedChecked));
        setChecked(not(checked, selectedChecked));
    };

    const handleCheckedSelected = () => {
        setSelectedRoles(selectedRoles.concat(availableChecked));
        setAvailableRoles(not(availableRoles, availableChecked));
        setChecked(not(checked, availableChecked));
    };

    const customList = (items) => (
        <Paper sx={{ width: 200, height: 150, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const handleCopyToProject = () => {
        if (copyRowData.length === 0) {
            enqueueSnackbar('Source data is empty!', { variant: 'error' });
            return;
        }

        //make sure only one project is selected
        let srcProject = copyRowData[0].project;
        setSourceProject(srcProject);
        if (copyRowData.find(x => x.project !== srcProject)) {
            enqueueSnackbar('Data from multiple project selected!', { variant: 'error' });
            return;
        }

        if (targetProject === '') {
            enqueueSnackbar('Target project is empty!', { variant: 'error' });
            return;
        }

        setDialogCopyProjectOpen(true);
    }

    function CustomFooterComponent(props) {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (

            <Box sx={{ p: 1, display: 'flex', borderTop: 1, borderColor: '#ddd' }}>
                <Box sx={{ m: 0, flex: 1 }}>
                    <Button
                        sx={{ margin: 0, height: 40 }}
                        variant="outlined"
                        onClick={handleUserNewClick}
                    >New user</Button>
                </Box>

                <Box >
                    <Pagination
                        sx={{ height: 37 }} /* to prevent changing footer height on selected rows */
                        color="primary"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => apiRef.current.setPage(value - 1)}

                    />
                </Box>
            </Box>


        );
    }

    function CustomFooterComponentProjects() {
        return (
            <Box sx={{ p: 1, display: 'flex', borderTop: 1, borderColor: '#ddd' }}>
                <Box sx={{ m: 0, flex: 1 }}>
                    <Button
                        sx={{ margin: 0, height: 40 }}
                        variant="outlined"
                        onClick={() => {
                            setDialogNewProjectOpen(true);
                            setNewProjectName("");
                        }}
                    >New project</Button>
                </Box>
            </Box>
        );
    }

    function CustomFooterComponentBlocks() {
        return (
            <Box sx={{ p: 1, display: 'flex', borderTop: 1, borderColor: '#ddd' }}>
                <Box sx={{ m: 0, flex: 1 }}>
                    <Button
                        sx={{ margin: 0, height: 40 }}
                        variant="outlined"
                        onClick={() => {
                            setDialogNewBlockOpen(true);
                            setNewBlockName("");
                        }}
                    >New block</Button>
                </Box>
            </Box>
        );
    }

    function CustomFooterCopyComponent(props) {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (

            <Box sx={{ p: 1, display: 'flex', borderTop: 1, borderColor: '#ddd' }}>
                <FormControl sx={{ m: 1, width: 150 }} size="small">
                    <InputLabel id="targetProjectEdit-label">Target project</InputLabel>
                    <Select
                        labelId="targetProjectEdit-label"
                        id="targetProjectEdit"
                        value={targetProject}
                        onChange={event => setTargetProject(event.target.value)}
                        input={<OutlinedInput id="select-multiple-chip" label="Target project" />}
                    // MenuProps={MenuProps}
                    >
                        {allProjects.map(option => {
                            return (
                                <MenuItem key={option.name} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <Box sx={{ m: 0, flex: 1 }}>
                    <Button
                        sx={{ margin: 1, height: 40 }}
                        variant="outlined"
                        onClick={handleCopyToProject}
                    >Copy to Project</Button> <small>This action will copy all the definitions (selection criteria and restructuring) from above table to the target project</small>
                </Box>

                <Box >
                    <Pagination
                        sx={{ height: 37 }} /* to prevent changing footer height on selected rows */
                        color="primary"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => apiRef.current.setPage(value - 1)}

                    />
                </Box>
            </Box>


        );
    }

    const handleCloseDialogCopyProject = () => {
        setDialogCopyProjectOpen(false);
    }

    const handleCopyDefiniton = () => {
        let reqData = {
            "fromProject": sourceProject,
            "toProject": targetProject,
            "selection": copyFilterDef
        }
        console.log(reqData);
        UserService.copySelectionCriteria(reqData).then(resp => {
            UserService.copyRestructuring(reqData).then(resp => {
                enqueueSnackbar('Successfully copied definitions to ' + targetProject, { variant: 'success' });
            },
                error => {
                    const resMessage =
                        (error.response.data.errorMessage) ||
                        error.message ||
                        error.toString();
                    console.log(resMessage);
                    enqueueSnackbar(resMessage, { variant: 'error' });

                });
        },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                console.log(resMessage);
                enqueueSnackbar(resMessage, { variant: 'error' });

            });
        setDialogCopyProjectOpen(false);
    }

    const handleFilterUpdate = (value) => {
        setCopyFilterDef(value);
        UserService.getSelCritWithFilter(value).then(
            response => {
                setCopyRowData(response.data);
            },
            error => {
                setCopyRowData([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    };

    const commonStyles = {
        bgcolor: 'background.paper',
        mt: 1,
        p: 1,
        border: 1,
        borderRadius: '10px',
        borderColor: '#ddd'
    };

    return (
        <div>
            <h3>Settings</h3>
            <Box sx={{ ...commonStyles }} >
                <Box sx={{ m: 0, flex: 1 }} style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <h4>New definition</h4><small>Creates a new definition based on API/Transaction in M3 Cloud (MRS001/002)</small>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        // alignItems: 'flex-start',
                        flexDirection: 'row',
                        // p: 1,
                        // m: 1,
                        // bgcolor: 'background.paper',
                        // borderRadius: 1,
                    }}
                >
                    <FormControl sx={{ m: 1, width: 100 }} size="small">
                        <InputLabel id="newDefProjectEdit-label">Project</InputLabel>
                        <Select
                            labelId="newDefProjectEdit-label"
                            id="newDefProjectEdit"
                            value={newDefProject}
                            onChange={event => setNewDefProject(event.target.value)}
                            input={<OutlinedInput id="select-multiple-chip" label="Project" />}
                        // MenuProps={MenuProps}
                        >
                            {allProjects.map(option => {
                                return (
                                    <MenuItem key={option.name} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 100 }} size="small">
                        <InputLabel id="newDefBlockEdit-label">Block</InputLabel>
                        <Select
                            labelId="newDefBlockEdit-label"
                            id="newDefBlockEdit"
                            value={newDefBlock}
                            onChange={event => setNewDefBlock(event.target.value)}
                            input={<OutlinedInput id="select-multiple-chip" label="Block" />}
                        // MenuProps={MenuProps}
                        >
                            {allBlocks.map(option => {
                                return (
                                    <MenuItem key={option.name} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 150 }}>
                        <TextField id="Layoutc"
                            label="Layout"
                            variant="outlined"
                            value={newDefLayout}
                            required
                            error={formError && newDefLayout.length === 0}
                            // helperText={errors['name'] ? errors['name'].message : ''}
                            helperText={formError && newDefLayout.length === 0 ? "Empty" : ""}
                            onChange={event => setNewDefLayout(event.target.value)}
                            size="small" />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 150 }}>
                        <TextField id="API"
                            label="API"
                            variant="outlined"
                            value={newDefAPI}
                            required
                            error={formError && newDefAPI.length === 0}
                            helperText={formError && newDefAPI.length === 0 ? "Empty" : ""}
                            onChange={event => setNewDefAPI(event.target.value)}
                            size="small" />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 150 }}>
                        <TextField id="Transaction"
                            label="Transaction"
                            variant="outlined"
                            value={newDefTran}
                            required
                            error={formError && newDefTran.length === 0}
                            helperText={formError && newDefTran.length === 0 ? "Empty" : ""}
                            onChange={event => setNewDefTran(event.target.value)}
                            size="small" />
                    </FormControl>
                    <Button
                        sx={{ margin: 1, height: 40 }}
                        variant="outlined"
                        onClick={handleNewDefClick}
                    >Create new definion</Button>

                </Box>
            </Box>
            <Box sx={{ ...commonStyles }} >

                <h4>Copy definition</h4>
                <SelFilter handleFilter={handleFilterUpdate} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <Box sx={{ height: '35vh', width: '100%' }}>
                        <DataGrid
                            rows={copyRowData}
                            columns={columnsCopyProject}
                            columnVisibilityModel={{
                                id: false
                            }}
                            density="compact"
                            disableRowSelectionOnClick
                            rowHeight={32}
                            pagination
                            pageSize={100}
                            pageSizeOptions={[100]}
                            slots={{
                                footer: CustomFooterCopyComponent,
                            }}
                        />
                    </Box>

                </Box>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={8}>

                    <Box sx={{ ...commonStyles }} >
                        <h4>Users</h4>
                        {/* <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >

                    <Button
                        sx={{ margin: 1, height: 40 }}
                        variant="outlined"
                        onClick={handleUserNewClick}
                    >New user</Button></Box> */}


                        <Box sx={{ height: '35vh', width: '100%' }}>
                            <DataGrid
                                rows={allUsers}
                                columns={columnsUser}
                                columnVisibilityModel={{
                                    id: false
                                }}
                                density="compact"
                                experimentalFeatures={{ newEditingApi: true }}
                                disableRowSelectionOnClick
                                rowHeight={32}
                                pagination
                                pageSize={100}
                                pageSizeOptions={[100]}
                                slots={{
                                    footer: CustomFooterComponent,
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box sx={{ ...commonStyles }} >
                        <h4>Projects</h4>
                        <Box sx={{ height: '35vh', width: '100%' }}>
                            <DataGrid
                                rows={allProjects}
                                columns={columnsProjectsAndBlocks}
                                density="compact"
                                disableRowSelectionOnClick
                                rowHeight={32}
                                pagination
                                pageSize={100}
                                slots={{
                                    footer: CustomFooterComponentProjects,
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box sx={{ ...commonStyles }} >
                        <h4>Blocks</h4>
                        <Box sx={{ height: '35vh', width: '100%' }}>
                            <DataGrid
                                rows={allBlocks}
                                columns={columnsProjectsAndBlocks}
                                density="compact"
                                disableRowSelectionOnClick
                                rowHeight={32}
                                pagination
                                pageSize={100}
                                slots={{
                                    footer: CustomFooterComponentBlocks,
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>

            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <Box sx={{ ...commonStyles }} >
                        <h4>Settings</h4>
                 
                        <Box sx={{ height: '30vh', width: '100%' }}>
                            <DataGrid
                                rows={allAppSettings}
                                columns={columnsAppSetting}
                                columnVisibilityModel={{
                                    id: false
                                }}
                                density="compact"
                                experimentalFeatures={{ newEditingApi: true }}
                                disableRowSelectionOnClick
                                rowHeight={32}
                                pagination
                                pageSize={100}
                                pageSizeOptions={[100]}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>


            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">
                    {"Create a new definition?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        Would you like to create a new definitions<br />
                        Project: <b>{newDefProject}</b><br />
                        Block: <b>{newDefBlock}</b><br />
                        Layout: <b>{newDefLayout}</b><br />
                        Table: <b>{newDefAPI}_{newDefTran}</b><br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>No</Button>
                    <Button onClick={createNewDefiniton} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogCopyProjectOpen}
                onClose={handleCloseDialogCopyProject}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">
                    {"Copy definitions?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        Would you like to copy definitions<br />
                        From project: <b>{sourceProject}</b><br />
                        To project: <b>{targetProject}</b><br />
                        For: <b>{copyRowData.length} table{copyRowData.length > 1 ? "s" : ""}</b><br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogCopyProject}>No</Button>
                    <Button onClick={handleCopyDefiniton} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogUserOpen}
                onClose={handleCloseUserDialog}
                aria-labelledby="dialogUser-title"
                aria-describedby="dialogUser-description"
            >
                <DialogTitle id="dialogUser-title">
                    {handleUserNewClick.id == undefined ? "Create user" : "Edit user"}
                </DialogTitle>
                <DialogContent>
                    <FormControl sx={{ m: 1, width: 350 }}>
                        <TextField id="UserEmail"
                            label="email"
                            variant="outlined"
                            value={userEmail}
                            required
                            error={formUserError && userEmail.length === 0}
                            // helperText={errors['name'] ? errors['name'].message : ''}
                            helperText={formUserError && userEmail.length === 0 ? "Empty" : ""}
                            onChange={event => setUserEmail(event.target.value)}
                            size="small" />
                    </FormControl>
                    <br />
                    <FormControl sx={{ m: 1, width: 350 }} size="small">
                        <InputLabel id="newDefProjectEdit-label">User type</InputLabel>
                        <Select
                            labelId="newDefProjectEdit-label"
                            id="newDefProjectEdit"
                            value={userType}
                            onChange={event => setUserType(event.target.value)}
                            input={<OutlinedInput id="select-multiple-chip" label="User type" />}
                        // MenuProps={MenuProps}
                        >
                            {constants.USER_TYPES.map(option => {
                                return (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.val}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <br />
                    <FormControl sx={{ m: 1, width: 350 }}>
                        <TextField id="UserLogin"
                            label="Login"
                            variant="outlined"
                            value={userLogin}
                            required
                            error={formUserError && userLogin.length === 0}
                            // helperText={errors['name'] ? errors['name'].message : ''}
                            helperText={formUserError && userLogin.length === 0 ? "Empty" : ""}
                            onChange={event => setUserLogin(event.target.value)}
                            size="small" />
                    </FormControl>
                    <br />
                    {userType === constants.USER_TYPE_LOCAL ? 
                        <FormControl sx={{ m: 1, width: 350 }}>
                            <TextField id="UserPassword"
                                label="Password"
                                variant="outlined"
                                value={userPassword}
                                required
                                type="password"
                                error={formUserError && userPassword.length === 0}
                                // helperText={errors['name'] ? errors['name'].message : ''}
                                helperText={formUserError && userPassword.length === 0 ? "Empty" : ""}
                                onChange={event => setUserPassword(event.target.value)}
                                size="small" />
                        </FormControl>
                     :""}
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUserDialog}>Cancel</Button>
                    <Button onClick={handleUserSave}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogUserEditOpen}
                onClose={handleCloseUserEditDialog}
                aria-labelledby="dialogUserEdit-title"
                aria-describedby="dialogUserEdit-description"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id="dialogUserEdit-title">
                    Edit user {userToEdit.login}
                </DialogTitle>
                <DialogContent>
                    <br />
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <FormControl sx={{ m: 1, width: 350 }}>
                            <TextField id="UserEmail"
                                label="email"
                                variant="outlined"
                                value={userToEdit.email}
                                required
                                error={formUserEditError && userToEdit.email.length === 0}
                                // helperText={errors['name'] ? errors['name'].message : ''}
                                helperText={formUserEditError && userToEdit.email.length === 0 ? "Empty" : ""}
                                onChange={event => {
                                    let tmp = {
                                        ...userToEdit
                                    };
                                    tmp.email = event.target.value;
                                    setUserToEdit(tmp);
                                }}
                                size="small" />
                        </FormControl>
                        <Grid item>Selected roles{customList(selectedRoles)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedAvailable}
                                    disabled={selectedChecked.length === 0}
                                    aria-label="move available"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedSelected}
                                    disabled={availableChecked.length === 0}
                                    aria-label="move selected"
                                >
                                    &lt;
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>Available roles{customList(availableRoles)}</Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUserEditDialog}>Cancel</Button>
                    <Button onClick={handleSaveUserEditDialog}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogAppSettingEditOpen}
                onClose={handleCloseAppSettingEditDialog}
                aria-labelledby="dialogAppSettingEdit-title"
                aria-describedby="dialogAppSettingEdit-description"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle id="dialogAppSettingEdit-title">
                    Edit Setting {appSettingToEdit.name}
                </DialogTitle>
                <DialogContent>
                    <br />
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <FormControl sx={{ m: 1, width: 350 }}>
                            <TextField id="AppSettingName"
                                label="Name"
                                variant="outlined"
                                value={appSettingToEdit.name}
                                readonly
                                size="small" />
                        </FormControl>
                        <FormControl sx={{ m: 1, width: 350 }}>
                            <TextField id="AppSettingValue"
                                label="Value"
                                variant="outlined"
                                value={appSettingToEdit.value}
                                required
                                error={formAppSettingEditError && appSettingToEdit.value.length === 0}
                                // helperText={errors['name'] ? errors['name'].message : ''}
                                helperText={formAppSettingEditError && appSettingToEdit.value.length === 0 ? "Empty" : ""}
                                onChange={event => {
                                    let tmp = {
                                        ...appSettingToEdit
                                    };
                                    tmp.value = event.target.value;
                                    setAppSettingToEdit(tmp);
                                }}
                                size="small" />
                        </FormControl>
                        <FormControl sx={{ m: 1, width: 350 }}>
                            <TextField id="AppSettingDescription"
                                label="Description"
                                variant="outlined"
                                value={appSettingToEdit.description}
                                multiline
                                required
                                error={formAppSettingEditError && appSettingToEdit.description.length === 0}
                                // helperText={errors['name'] ? errors['name'].message : ''}
                                helperText={formAppSettingEditError && appSettingToEdit.description.length === 0 ? "Empty" : ""}
                                onChange={event => {
                                    let tmp = {
                                        ...appSettingToEdit
                                    };
                                    tmp.description = event.target.value;
                                    setAppSettingToEdit(tmp);
                                }}
                                size="small" />
                        </FormControl>
                        {appSettingToEdit.name === "lockdown" ? <Stack sx={{ width: '80%', pb: 1 }} >
                <Alert variant="outlined" sx={{
                    pb: 0, pt: 0,
                    "& .MuiAlert-icon": {
                        fontSize: 20,
                        paddingTop: 0.3,
                        paddingBottom: 0.3
                    },
                    "& .MuiAlert-message": {
                        paddingTop: 0.3,
                        paddingBottom: 0.3
                    }
                }} severity="warning">Updating this app setting will logoff each user immediately.</Alert>
            </Stack>
                : ""}
                        
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAppSettingEditDialog}>Cancel</Button>
                    <Button onClick={handleSaveAppSettingEditDialog}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogNewProjectOpen}
                onClose={() => { setDialogNewProjectOpen(false) }}
                aria-labelledby="dialogNewProject-title"
                aria-describedby="dialogNewProject-description"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle id="dialogNewProject-title">
                    New project
                </DialogTitle>
                <DialogContent>
                    <FormControl sx={{ m: 1, width: 150 }}>
                        <TextField id="newProjectName"
                            label="Project"
                            variant="outlined"
                            value={newProjectName}
                            required
                            error={formError && newProjectName.length === 0}
                            // helperText={errors['name'] ? errors['name'].message : ''}
                            helperText={formError && newProjectName.length === 0 ? "Empty" : ""}
                            onChange={event => setNewProjectName(event.target.value)}
                            size="small" />
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDialogNewProjectOpen(false) }}>Cancel</Button>
                    <Button onClick={handleSaveNewProjectDialog}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogNewBlockOpen}
                onClose={() => { setDialogNewBlockOpen(false) }}
                aria-labelledby="dialogNewBlock-title"
                aria-describedby="dialogNewBlock-description"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle id="dialogNewBlock-title">
                    New Block
                </DialogTitle>
                <DialogContent>
                    <FormControl sx={{ m: 1, width: 150 }}>
                        <TextField id="newBlockName"
                            label="Block"
                            variant="outlined"
                            value={newBlockName}
                            required
                            error={formError && newBlockName.length === 0}
                            // helperText={errors['name'] ? errors['name'].message : ''}
                            helperText={formError && newBlockName.length === 0 ? "Empty" : ""}
                            onChange={event => setNewBlockName(event.target.value)}
                            size="small" />
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDialogNewBlockOpen(false) }}>Cancel</Button>
                    <Button onClick={handleSaveNewBlockDialog}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogUserDeleteOpen}
                onClose={() => { setDialogUserDeleteOpen(false) }}
                aria-labelledby="dialogNewBlock-title"
                aria-describedby="dialogNewBlock-description"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogTitle id="dialogNewBlock-title">
                    Are you sure?
                </DialogTitle>
                <DialogContent>
                    Delete user {userToDelete.login}

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDialogUserDeleteOpen(false) }}>Cancel</Button>
                    <Button onClick={() => {
                        UserService.deleteUser(userToDelete.id).then(resp => {
                            enqueueSnackbar('Successfully deleted user ' + userToDelete.login, { variant: 'success' });
                            setDialogUserDeleteOpen(false);
                            refreshUsers();
                        },
                            error => {
                                const resMessage =
                                    (error.response.data.errorMessage) ||
                                    error.message ||
                                    error.toString();
                                enqueueSnackbar(resMessage, { variant: 'error' });

                            });
                    }}>Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Settings;