import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Container, Paper, Typography, Box } from '@mui/material';
import UserService from '../services/user.service';

import SelFilter from "./selFilter.component";

const XrefLogs = () => {
    const [logs, setLogs] = useState([]);
    const [groupedLogs, setGroupedLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedGroups, setExpandedGroups] = useState(new Set());

    const columns = [
        {
            field: 'batch',
            headerName: 'Batch',
            width: 100,
            renderCell: (params) => {
                if (params.row.isGroup) {
                    return (
                        <div style={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            color: params.row.hasUnfinishedItems ? '#d32f2f' : 'inherit' 
                        }}>
                            {expandedGroups.has(params.row.id) ? '▼' : '▶'} 
                            <strong style={{ marginLeft: '8px' }}>
                                {params.value}
                                {/*  ({params.row.count} items) */}
                            </strong>
                        </div>
                    );
                }
                return <span style={{ marginLeft: '25px' }}>{params.value}</span>;
            }
        },
        { field: 'id', headerName: 'ID', width: 100 },
        { 
            field: 'project', 
            headerName: 'Project', 
            width: 150, 
            groupable: true,
            renderCell: (params) => {
                if (params.row.isGroup) {
                    return (
                        <span style={{ color: params.row.hasUnfinishedItems ? '#d32f2f' : 'inherit' }}>
                            {params.value}
                        </span>
                    );
                }
                return params.value;
            }
        },
        { field: 'tableName', headerName: 'Table Name', width: 200 },
        { field: 'destination', headerName: 'Destination', width: 120 },
        { field: 'state', headerName: 'State', width: 120 },
        { 
            field: 'count', 
            headerName: 'Count', 
            width: 100,
            renderCell: (params) => {
                if (params.row.isGroup) {
                    return (
                        <span style={{ color: params.row.hasUnfinishedItems ? '#d32f2f' : 'inherit' }}>
                            {params.value}
                        </span>
                    );
                }
                return params.value;
            }
        },
        {
            field: 'timestamp',
            headerName: 'Timestamp',
            width: 200,
            renderCell: (params) => {
                const formattedTime = params.row.timestamp ? formatTimestamp(params.row.timestamp) : '';
                if (params.row.isGroup) {
                    return (
                        <span style={{ color: params.row.hasUnfinishedItems ? '#d32f2f' : 'inherit' }}>
                            {formattedTime}
                        </span>
                    );
                }
                return formattedTime;
            }
        },
        { field: 'message', headerName: 'Message', width: 300 },
    ];

    useEffect(() => {
        fetchLogs();
    }, []);

    const formatTimestamp = (timestamp) => {
        // Return early if timestamp is undefined or null
        if (!timestamp) return '';
        
        // Convert to string if it's a number
        const timeStr = timestamp.toString();
        
        // Assuming timestamp is in format: YYYYMMDDHHMMSS
        const year = timeStr.substring(0, 4);
        const month = timeStr.substring(4, 6);
        const day = timeStr.substring(6, 8);
        const hour = timeStr.substring(8, 10);
        const minute = timeStr.substring(10, 12);
        const second = timeStr.substring(12, 14);
        
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    };

    const groupLogs = (data) => {
        const grouped = data.reduce((acc, log) => {
            const date = new Date(log.timestamp);
            const groupKey = `${log.batch}-${log.project}-${date.toISOString().slice(0, 13)}`;
            
            if (!acc[groupKey]) {
                acc[groupKey] = {
                    id: groupKey,
                    batch: log.batch,
                    project: log.project,
                    timestamp: log.timestamp,
                    count: 0,
                    isGroup: true,
                    children: [],
                    hasUnfinishedItems: false
                };
            }
            
            acc[groupKey].count++;
            acc[groupKey].children.push({
                ...log,
                id: `${groupKey}-${log.id}`,
                parentId: groupKey
            });
            
            if (log.message !== 'Finished') {
                acc[groupKey].hasUnfinishedItems = true;
            }
            
            return acc;
        }, {});

        const result = Object.values(grouped).flatMap(group => [
            group,
            ...group.children
        ]);

        return result;
    };

    const fetchLogs = async () => {
        try {
            const response = await UserService.getXrefLogs();
            const groupedData = groupLogs(response.data);
            setLogs(response.data);
            setGroupedLogs(groupedData);
            
            setLoading(false);
        } catch (error) {
            console.error('Error fetching logs:', error);
            setLoading(false);
        }
    };

    const handleFilterUpdate = (value) => {
        //TODO - apply the project filtering here        
        fetchLogs();
    };

    function QuickSearchToolbar() {
        return (
            <Box
                sx={{
                    p: 0.5,
                    pb: 0,
                }}
            >
                <GridToolbarQuickFilter />
            </Box>
        );
    }

    const toggleGroup = (groupId) => {
        setExpandedGroups(prev => {
            const next = new Set(prev);
            if (next.has(groupId)) {
                next.delete(groupId);
            } else {
                next.add(groupId);
            }
            return next;
        });
    };

    const getVisibleRows = () => {
        return groupedLogs.filter(row => {
            if (row.isGroup) return true;
            return expandedGroups.has(row.parentId);
        });
    };

    return (
        <div style={{ height: '85vh', width: '100%' }}>
            <header className="">
                <h3>Cross Reference Logs</h3>
                <SelFilter
                    handleFilter={handleFilterUpdate}
                    useBlock={false}
                    useLayout={false}
                    useTable={false}
                    useStatus={false} /* TODO - implement useStatus in SelFilter */
                />

            </header>
            <Box sx={{ height: '85vh', width: '100%' }}>

                <div style={{ height: '85vh', width: '100%' }}>
                    <DataGrid
                        rows={getVisibleRows()}
                        columns={columns}
                        onRowClick={(params) => {
                            if (params.row.isGroup) {
                                toggleGroup(params.row.id);
                            }
                        }}
                        columnVisibilityModel={{
                            id: false
                        }}
                        pageSize={10}
                        density="compact"
                        rowHeight={32}
                        disableSelectionOnClick
                        disableColumnSorting 
                        disableColumnFilter
                        loading={loading}
                        slots={{
                            toolbar: QuickSearchToolbar
                        }}
                        getRowClassName={(params) => 
                            params.row.isGroup ? 'super-app-theme--group' : 
                            !params.row.isGroup ? 'super-app-theme--detail' : ''
                        }
                        sx={{
                            '& .super-app-theme--group': {
                                backgroundColor: '#f5f5f5',
                                fontWeight: 'bold',
                                borderBottom: '1px solid #e0e0e0',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#e0e0e0',
                                }
                            },
                            '& .super-app-theme--detail': {
                                backgroundColor: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#f5f5f5',
                                }
                            }
                        }}
                    />
                </div>
            </Box>
        </div>


    );
};

export default XrefLogs;
