/* eslint-disable eqeqeq */
import React from "react";
import {
    DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector, GridToolbarQuickFilter
} from '@mui/x-data-grid';

import Pagination from '@mui/material/Pagination';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import UserService from "../services/user.service";
import AuthService from "../services/auth.service";

import SelFilter from "./selFilter.component";

//for dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';

import * as constants from '../common/constants'


import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';


import { GrEdit, GrHistory, GrList } from 'react-icons/gr'

import { addParamToUrl, formatdate, removeParamFromUrl, getCurrentTimestamp } from "../common/functions";
import RuleEditDialog from "./ruleEditDialog.component";

import { useSnackbar } from 'notistack';

import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import RuleMNDialog from "./ruleMNDialog.component";

import Autocomplete from '@mui/material/Autocomplete';

import UpdateStatusMultiple from "./multiStatusUpdate.component";
import { RenderCellExpand, GridCellExpand } from "../common/renderCellExpand";

import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';

import { triggerBase64Download } from "../common/base64downloader";

function DraggableComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

function Restructuring() {
    const headerDisplayOptions = { none: 'none', flex: 'flex' }
    const [hideHeaderText, setHideHeaderText] = React.useState(headerDisplayOptions.none);

    const { enqueueSnackbar } = useSnackbar();
    const [rowData, setRowData] = React.useState([]);
    const [selectedRowIDs, setSelectedRowIDs] = React.useState([]);
    const [selectedRowHistoryIDs, setSelectedRowHistoryIDs] = React.useState([]);
    const [rowDataHistory, setRowDataHistory] = React.useState([]);
    const [rowToEdit, setRowToEdit] = React.useState([]);
    const [mappingValue, setMappingValue] = React.useState([]);
    const [commentEdit, setCommentEdit] = React.useState('');
    const [statusEdit, setStatusEdit] = React.useState('');
    const [rules, setRules] = React.useState([]);
    const [openDialogEdit, setOpenDialogEdit] = React.useState(false);
    const [openDialogEditMultiple, setOpenDialogEditMultiple] = React.useState(false);
    const [openDialogHistory, setOpenDialogHistory] = React.useState(false);
    const [openDialogCompare, setOpenDialogCompare] = React.useState(false);
    const [compareL, setCompareL] = React.useState({});
    const [compareR, setCompareR] = React.useState({});

    const [showRulesRowEdit, setShowRulesRowEdit] = React.useState(false);
    const [rowToEditInline, setRowToEditInline] = React.useState({});

    const [MNToEdit, setMNToEdit] = React.useState(null);
    const [openDialogEditMN, setOpenDialogEditMN] = React.useState(false);


    const [openRuleDialogEdit, setOpenRuleDialogEdit] = React.useState(false);
    const [rowRuleToEdit, setRuleRowToEdit] = React.useState([]);
    const handleRuleCloseEdit = (reload, ruleId) => {
        removeParamFromUrl('editRule');
        setOpenRuleDialogEdit(false);
        if (openDialogEdit && ruleId > 0) {
            getAllRules();
            setMappingValue(ruleId);
        }
        //no need to reload since nothing in restructuring has been chagned
        // if (reload) {
        //     UserService.getRestructuringWithFilter(lastFilter).then(
        //         response => {
        //             setRowData(response.data);
        //         },
        //         error => {
        //             setRowData([]);
        //         }
        //     )
        // }
    };

    const showEditDialog = (row) => {
        setCommentEdit(row.comment);
        setStatusEdit(row.status);
        setRowToEdit(row);

        if (row.mappingType == 0) {
            setMappingValue(row.constant);
            setShowRules(false);
        }
        else if (row.mappingType == 1) {
            setMappingValue(row.sourceField)
            setShowRules(false);
        }
        else if (row.mappingType == 2) {
            setMappingValue(row.idRule)
            setShowRules(true);
        }

        setOpenDialogEdit(true);

    }

    const showHistoryDialog = (row) => {
        setRowToEdit(row);
        setRowDataHistory([]);
        UserService.getRestructuringHistory(row.id).then(
            response => {
                setRowDataHistory(response.data);
            },
            error => {
                setRowDataHistory([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );

        setOpenDialogHistory(true);

    }

    const handleCloseEdit = () => {
        setOpenDialogEdit(false);
        removeParamFromUrl('editRestructuring');
    };

    const handleCloseHistory = () => {
        removeParamFromUrl('showHistory');
        setOpenDialogHistory(false);
    };

    const updateRestr = (data) => {
        let row = {
            ...data
        };
        const id = row.id;
        row.id = null;

        UserService.updateRestructuring(id, row).then(res => {
            let tmpFilter = JSON.parse(localStorage.getItem('lastFilterRestructuring'));
            //refresh restructuring after sucessfull update
            UserService.getRestructuringWithFilter(tmpFilter).then(
                response => {
                    setRowData(response.data);
                    enqueueSnackbar('Successfully updated ' + row.fieldName, { variant: 'success' });
                },
                error => {
                    setRowData([]);
                    const resMessage =
                        (error.response.data.errorMessage) ||
                        error.message ||
                        error.toString();
                    enqueueSnackbar(resMessage, { variant: 'error' });
                }
            )
        },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    }

    const handleSaveEdit = () => {
        let row = {
            ...rowToEdit
        };
        const id = row.id;
        let mappingType = document.querySelector('[name="row-radio-buttons-group"]:checked').value;
        row.mappingType = mappingType;
        row.constant = '';
        row.sourceField = '';
        row.idRule = 0;
        if (mappingType == 0) {
            row.constant = mappingValue;
        } else if (mappingType == 1) {
            row.sourceField = mappingValue;
        } else if (mappingType == 2) {
            row.idRule = mappingValue; //TODO change to rules ID
        }

        row.id = null;
        row.comment = commentEdit;
        row.status = statusEdit;
        UserService.updateRestructuring(id, row).then(res => {
            let tmpFilter = JSON.parse(localStorage.getItem('lastFilterRestructuring'));
            //refresh restructuring after sucessfull update
            UserService.getRestructuringWithFilter(tmpFilter).then(
                response => {
                    setRowData(response.data);
                    enqueueSnackbar('Successfully updated ' + row.fieldName, { variant: 'success' });
                },
                error => {
                    setRowData([]);
                    const resMessage =
                        (error.response.data.errorMessage) ||
                        error.message ||
                        error.toString();
                    enqueueSnackbar(resMessage, { variant: 'error' });
                }
            )
        },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
        removeParamFromUrl('editRestructuring');
        setOpenDialogEdit(false);

    };

    const getAllRules = () => {
        UserService.getAllRules().then(
            response => {
                setRules(response.data.sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                }));
            },
            error => {
                setRules([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    }

    React.useEffect(() => {
        UserService.getAllRules().then(
            response => {
                setRules(response.data.sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                }));
            },
            error => {
                setRules([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );

    }, [enqueueSnackbar]);

    const handleExport = (value) => {

        UserService.downloadRestructuringData(value).then(
            response => {
                if (response.data.restructuringDataBase64) {
                    triggerBase64Download('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
                        + response.data.restructuringDataBase64, 'Restructuring_' + getCurrentTimestamp());
                }
            },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    }

    const handleFilterUpdate = (value) => {
        localStorage.setItem('lastFilterRestructuring', JSON.stringify(value));
        UserService.getAllRules().then(
            response => {
                setRules(response.data.sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                }));

                // show edit dialogs if param in url
                let url = new URL(window.location.href);
                let editRule = url.searchParams.get("editRule");
                if (editRule) {
                    let rule = response.data.find(x => x.id == editRule);
                    if (rule) {
                        setRuleRowToEdit(rule);
                        setOpenRuleDialogEdit(true);
                    } else {
                        removeParamFromUrl('editRule');
                    }

                }
                let editMN = url.searchParams.get("editMN");
                if (editMN) {
                    let rule = response.data.find(x => x.id == editMN);
                    if (rule) {
                        showEditMNDialog(rule);
                    } else {
                        removeParamFromUrl('editMN');
                    }

                }
            },
            error => {
                setRules([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );

        UserService.getRestructuringWithFilter(value).then(
            response => {
                setRowData(response.data);

                // show edit dialog if editRestructuring param is in url
                let url = new URL(window.location.href);
                let editRestructuring = url.searchParams.get("editRestructuring");
                if (editRestructuring) {
                    let row = response.data.find(x => x.id == editRestructuring);
                    showEditDialog(row);
                }
                let showHistory = url.searchParams.get("showHistory");
                if (showHistory) {
                    let row = response.data.find(x => x.id == showHistory);
                    showHistoryDialog(row);
                }
            },
            error => {
                setRowData([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    };

    const SelectEditStatusCell = (props) => {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
            apiRef.current.stopCellEditMode({ id, field });
        };

        return (
            <Select
                value={value}
                onChange={handleChange}
                size="small"
                sx={{ height: 1, padding: 0, m: 0, fontSize: 14 }}
                native
            // autoFocus
            >
                {constants.STATUSES.map(option => {
                    return (
                        <option key={option.id} value={option.id}>
                            {option.val}
                        </option>

                    )
                })}
            </Select>
        );
    }

    const renderSelectEditStatusCell = (params) => {
        return <SelectEditStatusCell {...params} />;
    };

    const SelectEditMappingCell = (props) => {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event) => {
            if (event.target.value == 2) {
                setShowRulesRowEdit(true);
            } else {
                setShowRulesRowEdit(false);
            }
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
            apiRef.current.stopCellEditMode({ id, field });
        };

        return (
            <Select
                value={value}
                onChange={handleChange}
                size="small"
                sx={{ height: 1, padding: 0, m: 0, fontSize: 14 }}
                native
            // autoFocus
            >
                {constants.MAPPINGS.map(option => {
                    return (
                        <option key={option.id} value={option.id}>
                            {option.val}
                        </option>

                    )
                })}
            </Select>
        );
    }

    const renderSelectEditMappingCell = (params) => {
        return <SelectEditMappingCell {...params} />;
    };

    const SelectEditValueRuleCell = (props) => {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();
        const handleChange = async (event) => {
            let fieldToUpdate = "";
            //TODO, this doesn't seems to be working since it takes the saved value anot the one before saving
            let mappingType = apiRef.current.getCellValue(id, 'mappingType');
            if (mappingType == 0) {
                fieldToUpdate = 'constant';
            } else if (mappingType == 1) {
                fieldToUpdate = 'sourceField';
            } else if (mappingType == 2) {
                fieldToUpdate = 'idRule';
            }
            await apiRef.current.setEditCellValue({ id, field: fieldToUpdate, value: event.target.value });
            apiRef.current.stopCellEditMode({ id, field });
        };

        return (
            <div>
                {showRulesRowEdit ? <Select
                    value={value}
                    onChange={handleChange}
                    size="small"
                    sx={{ height: 1, padding: 0, m: 0, fontSize: 14 }}
                    native
                // autoFocus
                >
                    {rules.map(option => {
                        return (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>

                        )
                    })}
                </Select>
                    : <input type="text" value={value} onChange={handleChange} />}
            </div>
        );
    }

    const renderSelectEditValueCell = (params) => {
        return <SelectEditValueRuleCell {...params} />;
    };


    const columns = [

        // {field: 'id', headerName: 'Id' },
        { field: 'id', headerName: 'Id' },
        {
            field: 'project', headerName: 'Project', width: '90',
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        { field: 'block', headerName: 'Block', width: AuthService.getAppSetting(constants.SETTING_COLWIDTHBLOCK)?.value ?? '60',
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        {
            field: 'tableName', headerName: 'Table', width: '200',
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        { field: 'layout', headerName: 'Layout', width: '70' },
        {
            field: 'position', headerName: '#', width: '20',
            type: "number"
        },
        {
            field: 'fieldName', headerName: 'Field', width: '80',
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        {
            field: 'fieldLong', headerName: 'Description', width: '140',
            renderCell: RenderCellExpand
        },
        { field: 'dataType', headerName: 'Type', width: '80' },
        {
            field: 'fieldLength', headerName: 'Length', width: '60',
            type: "number"
        },
        { field: 'fieldNumScale', headerName: 'Scale', width: '60',
            type: "number" 
        },
        {
            field: 'status',
            headerName: 'Status',
            width: '110',
            // editable: true,
            renderCell: (params) => {
                let status = "";
                status = constants.STATUSES.find(x => x.id === params.row.status)?.val;
                return status;
            },
            renderEditCell: renderSelectEditStatusCell
        },
        {
            field: 'mappingType',
            headerName: 'Mapping',
            width: '80',
            // editable: true,
            renderCell: (params) => {
                let mappingType = "";
                mappingType = constants.MAPPINGS.find(x => x.id == params.row.mappingType)?.val;
                return mappingType;
            },
            renderEditCell: renderSelectEditMappingCell
        },
        {
            field: 'value',
            headerName: 'Value',
            width: '180',
            // editable: true,
            renderCell: (params) => {
                if (params.row.mappingType == 0) {
                    return params.row.constant;
                }
                else if (params.row.mappingType == 1) {
                    return params.row.sourceField;
                }
                else if (params.row.mappingType == 2) {
                    const rule = rules.find(x => x.id === params.row.idRule);
                    const onClickEditRule = (e) => {
                        e.stopPropagation(); // don't select this row after clicking
                        UserService.getRule(params.row.idRule).then(response => {
                            let rule = response.data;
                            setRuleRowToEdit(rule);
                            addParamToUrl('editRule', rule.id);
                            setOpenRuleDialogEdit(true);
                        },
                            error => {
                                setRowDataHistory([]);
                            }
                        )
                    };
                    const onClickEditMN = (e) => {
                        e.stopPropagation(); // don't select this row after clicking
                        return showEditMNDialog(rule);
                    };

                    // return rule == undefined ? params.row.idRule : <Box sx={{ p: 0, display: 'flex', borderTop: 0, flexDirection: 'row-reverse' }}>
                    return rule === undefined ? params.row.idRule : <Box sx={{ p: 0, display: 'flex', borderTop: 0, width: '100%' }}>
                        <Box>
                            <Button sx={{}} style={{
                                margin: 0,
                                padding: 0,
                                maxWidth: 130,
                                justifyContent: "flex-start",
                                // textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textAlign: "left",
                                display: "block"
                            }} size="small" onClick={onClickEditRule}>{rule.name}</Button>
                        </Box>
                        {rule.manyToMany === 1 ?
                            <Box sx={{ p:0, m: 0, flex: 1, textAlign: 'right' }}>
                                <IconButton sx={{ p:0 }} size="small" onClick={onClickEditMN}><GrList /></IconButton>
                            </Box>
                            : ""}
                    </Box >;
                }
            },
            renderEditCell: renderSelectEditValueCell
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            width: '80',
            renderCell: (params) => {
                const onClickEdit = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    addParamToUrl('editRestructuring', params.row.id);
                    return showEditDialog(params.row);
                };

                const onClickHistory = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    addParamToUrl('showHistory', params.row.id);
                    return showHistoryDialog(params.row);
                };

                return <span>
                    {AuthService.hasPermission(constants.PERMISSION_EDIT) ?
                        <IconButton sx={{ pt:0.1 }} size="small" onClick={onClickEdit}><GrEdit /></IconButton>
                        : ""}
                    <IconButton sx={{ pt:0.1 }} size="small" onClick={onClickHistory}><GrHistory /></IconButton>
                </span>;
            },
        },
        {
            field: 'comment',
            headerName: 'Comment',
            renderCell: RenderCellExpand
            // editable: true
        },
        {
            field: 'modifiedAt',
            headerName: 'Last change',
            width: '150',
            renderCell: (params) => {
                return formatdate(params.row.modifiedAt);
            }
        },
        { field: 'modfiedBy', headerName: 'User', width: '120' },
        { field: 'constant', editable: true },
        { field: 'sourceField', editable: true },
        { field: 'idRule', editable: true },
        { field: 'lock' },
    ];

    const columnsHistory = [
        { field: 'tableName', headerName: 'Table', width: '140' },
        { field: 'fieldName', headerName: 'Field', width: '70' },
        {
            field: 'status',
            headerName: 'Status',
            width: '110',
            renderCell: (params) => {
                let status = "";
                status = constants.STATUSES.find(x => x.id === params.row.status)?.val;
                return status;
            },
        },
        {
            field: 'mappingType',
            headerName: 'Mapping',
            width: '80',
            renderCell: (params) => {
                let status = "";
                status = constants.MAPPINGS.find(x => x.id === params.row.mappingType).val;
                return status;
            },
        },
        {   field: 'value', headerName: 'Value',
            renderCell: (params) => {
                let tmpVal = "";
                if (params.row.mappingType == 0) {
                    tmpVal = params.row.constant;
                }
                else if (params.row.mappingType == 1) {
                    tmpVal = params.row.sourceField;
                }
                else if (params.row.mappingType == 2) {
                    const rule = rules.find(x => x.id === params.row.idRule);
                    tmpVal = rule === undefined ? params.row.idRule : rule.name;
                }

                return (
                    <GridCellExpand
                        value={tmpVal || ""}
                        width={params.colDef.computedWidth + 200}
                    />
                );
            },
        },
        {
            field: 'comment', headerName: 'Comment', width: '200',
            renderCell: RenderCellExpand
        },
        {
            field: 'modifiedAt',
            headerName: 'Last change',
            width: '150',
            renderCell: (params) => {
                return formatdate(params.row.modifiedAt);
            }
        },
        { field: 'modfiedBy', headerName: 'User', width: '120' },
        { field: 'constant' },
        { field: 'sourceField' },
        { field: 'idRule' },
        { field: 'position' },
        { field: 'lock' },
        { field: 'fieldNumScale' },
    ];

    const [showRules, setShowRules] = React.useState(false);

    const handleMappingTypeChange = (event) => {
        setMappingValue("");
        let value = event.target.value;
        if (value == 0 || value == 1) {
            //show text
            setShowRules(false);
        } else if (value == 2) {
            //show radio
            setShowRules(true);
        }
    }

    function CustomFooterStatusComponent(props) {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (

            <Box sx={{ p: 1, display: 'flex', borderTop: 1, borderColor: '#ddd' }}>
                <Box sx={{ m: 0, flex: 1 }}>
                    {selectedRowIDs.length > 0 && AuthService.hasPermission(constants.PERMISSION_EDIT) ?
                        <Button
                            sx={{ m: 0 }}
                            variant="outlined"
                            onClick={() => { setOpenDialogEditMultiple(true) }}
                        >Set status for {selectedRowIDs.length} selected rows</Button>
                        : ""}
                </Box>

                <Box >
                    <Pagination
                        sx={{ height: 37 }} /* to prevent changing footer height on selected rows */
                        color="primary"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => apiRef.current.setPage(value - 1)}

                    />
                </Box>
            </Box>


        );
    }

    const onClickNewRule = (e) => {
        setRuleRowToEdit([]);
        setOpenRuleDialogEdit(true);

    };

    const showEditMNDialog = (row) => {
        setMNToEdit(row);
        setOpenDialogEditMN(true);
        addParamToUrl('editMN', row.id);
    }

    const handleCloseEditMN = (reload) => {
        setMNToEdit(null);
        setOpenDialogEditMN(false);
        removeParamFromUrl('editMN');
    };

    const HandleUpdateStatusMultiple = (status) => {
        setOpenDialogEditMultiple(false);

        let updateData = {
            "status": status,
            "ids": selectedRowIDs
        }
        UserService.updateRestructuringMultipleStatus(updateData).then(res => {
            let tmpFilter = JSON.parse(localStorage.getItem('lastFilterRestructuring'));
            //refresh restructuring after sucessfull update
            UserService.getRestructuringWithFilter(tmpFilter).then(
                response => {
                    setRowData(response.data);
                    setSelectedRowIDs([]);
                    enqueueSnackbar('Successfully updated status', { variant: 'success' });
                },
                error => {
                    setRowData([]);
                    const resMessage =
                        (error.response.data.errorMessage) ||
                        error.message ||
                        error.toString();
                    enqueueSnackbar(resMessage, { variant: 'error' });
                }
            )
        },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    }

    function QuickSearchToolbar() {
        return (
            <Box
                sx={{
                    p: 0.5,
                    pb: 0,
                }}
            >
                <GridToolbarQuickFilter />
            </Box>
        );
    }

    function CustomFooterHistoryStatusComponent(props) {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        // this seems thrown an error on console when opening the history - TODO: check why
        // setHideHeaderText(selectedRowHistoryIDs.length > 0 ? headerDisplayOptions.flex : headerDisplayOptions.none)

        return (

            <Box sx={{ p: 1, display: 'flex', borderTop: 1, borderColor: '#ddd' }}>
                <Box sx={{ m: 0, flex: 1 }}>

                    {selectedRowHistoryIDs.length == 2 ?
                        <Button
                            sx={{ m: 0 }}
                            variant="outlined"
                            onClick={() => {
                                setOpenDialogCompare(true)
                                let sortedHistory = selectedRowHistoryIDs.sort()
                                if (sortedHistory.length == 2) {
                                    // const getSelectedUser = (userId) => users.find(({id}) => id == userId)
                                    const leftId = sortedHistory[0]
                                    const getHistoryRecord = (leftId) => rowDataHistory.find(({ modifiedAt }) => modifiedAt == leftId)
                                    let leftHist = getHistoryRecord(sortedHistory[0])
                                    let rightHist = getHistoryRecord(sortedHistory[1])
                                    setCompareL(leftHist);
                                    setCompareR(rightHist);
                                } else {
                                    setCompareL({});
                                    setCompareR({});
                                }
                            }}
                        >Compare selected rows</Button>
                        : <Button
                            sx={{ m: 0 }}
                            variant="outlined"
                            disabled={true}
                        >Select 2 rows to compare</Button>}
                </Box>

                <Box >
                    <Pagination
                        sx={{ height: 37 }} /* to prevent changing footer height on selected rows */
                        color="primary"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => apiRef.current.setPage(value - 1)}

                    />
                </Box>
            </Box>


        );
    }

    // Add helper function to check if editing is frozen
    const isEditingFrozen = (status) => {
        return AuthService.getAppSetting(constants.SETTING_FREEZEEDITFORIMPLEMENTED)?.value === "true" 
            && (status === 'A' || status === 'I');
    };

    return (
        <div style={{ height: '88vh', width: '100%' }}>
            <header className="">
                <h3>Restructuring</h3>
                {/* <h3>{this.state.content}</h3> */}
                <SelFilter 
                    handleFilter={handleFilterUpdate} 
                    handleExport={handleExport}
                    showExport={true}/>
            </header>
            <Box sx={{ height: '88vh', width: '100%' }}>
                <DataGrid
                    rows={rowData}
                    columns={columns}
                    columnVisibilityModel={{
                        id: false,
                        constant: false,
                        sourceField: false,
                        idRule: false,
                        lock: false,
                        fieldNumScale: AuthService.getAppSetting(constants.SETTING_SHOWSCALE)?.value === "true" ? true : false
                    }}
                    density="compact"
                    editMode="row"
                    processRowUpdate={(newRow) => {
                        console.log(newRow);
                        updateRestr(newRow);
                        const updatedRow = { ...newRow, isNew: false };
                        setRowData(rowData.map((row) => (row.id === newRow.id ? updatedRow : row)));
                        return updatedRow;
                    }}
                    onRowEditStart={(params) => {
                        setRowToEditInline(params.row);
                    }}
                    onRowEditStop={(params) => {
                        setRowToEditInline({});
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowHeight={32}
                    getRowClassName={(params) => `gridRowColor-${params.row.status}`}
                    pagination
                    pageSize={100}
                    pageSizeOptions={[100]}
                    slots={{
                        footer: CustomFooterStatusComponent,
                        toolbar: QuickSearchToolbar
                    }}
                    rowSelectionModel={selectedRowIDs}
                    onRowSelectionModelChange={(ids, details) => {
                        setSelectedRowIDs(ids);
                    }}
                    sx={{
                        '.MuiDataGrid-cell--editing select': { pl: 1, pb: 0, pt: 0 },
                        '.MuiDataGrid-cell--editing input': { p: 1 }
                    }}

                // OnClickpageSize={pageSize}
                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                // rowsPerPageOptions={[100, 200, 400]}
                // pagination
                />
            </Box>

            <RuleMNDialog
                openDialogEdit={openDialogEditMN}
                rowToEdit={MNToEdit}
                onClose={handleCloseEditMN}
            />

            <Dialog
                // style={{ zIndex: 1700 }}
                open={openDialogEdit}
                onClose={handleCloseEdit}
                fullWidth={true}
                maxWidth="sm"
                PaperComponent={DraggableComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }}>Edit restructuring {rowToEdit.fieldName}<br />
                    <small>{rowToEdit.project} / {rowToEdit.block} / {rowToEdit.tableName}</small></DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <Box display="flex">
                        <FormControl sx={{ m: 1, width: 280 }}>

                            <FormLabel id="demo-row-radio-buttons-group-label">Mapping type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue={rowToEdit.mappingType}
                                onChange={handleMappingTypeChange}
                                disabled={isEditingFrozen(rowToEdit.status)}
                            >
                                <FormControlLabel value="0" control={<Radio disabled={isEditingFrozen(rowToEdit.status)} />} label="Constant" />
                                <FormControlLabel value="1" control={<Radio disabled={isEditingFrozen(rowToEdit.status)} />} label="Field" />
                                <FormControlLabel value="2" control={<Radio disabled={isEditingFrozen(rowToEdit.status)} />} label="Rule" />

                            </RadioGroup>

                        </FormControl>
                        {showRules ?
                            <FormControl sx={{ mt: 4.5, width: 100 }}>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={onClickNewRule}
                                    disabled={isEditingFrozen(rowToEdit.status)}
                                >New rule</Button>
                            </FormControl>
                            : ""}
                    </Box>
                    {/* <TextField id="outlined-basic" label="Value" variant="outlined" defaultValue={defaultValue} hidden sx={{ visibility: 'hidden' }}/> */}
                    {!showRules ?
                        <FormControl sx={{ m: 1, width: 200 }}>
                            <TextField 
                                id="outlined-basic"
                                label="Value"
                                variant="outlined"
                                value={mappingValue}
                                onChange={event => setMappingValue(event.target.value)}
                                disabled={isEditingFrozen(rowToEdit.status)}
                            />
                        </FormControl>
                        :
                        <FormControl sx={{ m: 1, width: 257 }} >
                            <Autocomplete
                                id="tags-outlined"
                                options={rules}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => setMappingValue(value?.id ? value.id : "")}
                                defaultValue={rules.find(x => x.id === rowToEdit.idRule)}
                                disabled={isEditingFrozen(rowToEdit.status)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Rule"
                                    />
                                )}
                            />
                        </FormControl>
                    }
                    <FormControl sx={{ m: 1, width: 257 }} >
                        <InputLabel id="statusEdit-label">Status</InputLabel>
                        <Select
                            labelId="statusEdit-label"
                            id="statudEdit"
                            value={statusEdit}
                            onChange={event => setStatusEdit(event.target.value)}
                            input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        >
                            {constants.STATUSES.map(option => {
                                return (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.val}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <br />
                    {/* <FormControl sx={{ m: 1, width: 420 }}>
                        <TextField id="outlined-basic"
                            label="Comment"
                            variant="outlined"
                            defaultValue={commentEdit}
                            onChange={event => setCommentEdit(event.target.value)} />
                    </FormControl> */}
                    <TextField 
                        id="outlined-basic"
                        label="Comment"
                        variant="outlined"
                        multiline
                        sx={{ m: 1, width: 530 }}
                        maxRows={6}
                        defaultValue={commentEdit}
                        onChange={event => setCommentEdit(event.target.value)}
                        disabled={isEditingFrozen(rowToEdit.status)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEdit}>Cancel</Button>
                    <Button onClick={handleSaveEdit}>Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialogHistory}
                onClose={handleCloseHistory}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle>History {rowToEdit.fieldName} ({rowToEdit.id})</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <div style={{ height: '400px' }}>
                        <DataGrid
                            rows={rowDataHistory}
                            columns={columnsHistory}
                            columnVisibilityModel={{
                                id: false,
                                constant: false,
                                sourceField: false,
                                idRule: false,
                                position: false,
                                lock: false,
                                fieldNumScale: false
                            }}
                            density="compact"
                            rowHeight={32}
                            getRowClassName={(params) => `gridRowColor-${params.row.status}`}
                            getRowId={(row) => row.modifiedAt}
                            checkboxSelection
                            slots={{
                                footer: CustomFooterHistoryStatusComponent,
                                toolbar: QuickSearchToolbar
                            }}
                            rowSelectionModel={selectedRowHistoryIDs}
                            onRowSelectionModelChange={(ids, details) => {
                                setSelectedRowHistoryIDs(ids);
                            }}
                            sx={{
                                '.MuiDataGrid-cell--editing select': { pl: 1, pb: 0, pt: 0 },
                                '.MuiDataGrid-cell--editing input': { p: 1 },
                                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                    // display: "flex"
                                    display: headerDisplayOptions[hideHeaderText] ?? "flex"
                                }
                            }}
                        />
                    </div>
                    <FormControl>

                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseHistory}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDialogCompare}
                onClose={() => { setOpenDialogCompare(false) }}
                maxWidth="false"
            >
                <DialogTitle>History compare</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <ReactDiffViewer
                        oldValue={compareL.comment ?? ""}
                        newValue={compareR.comment ?? ""}
                        leftTitle={"Comment - " + formatdate(compareL.modifiedAt ?? "")}
                        rightTitle={"Comment - " + formatdate(compareR.modifiedAt ?? "")}
                        splitView={true}
                        showDiffOnly={false} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenDialogCompare(false) }}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <RuleEditDialog
                openDialogEdit={openRuleDialogEdit}
                rowToEdit={rowRuleToEdit}
                onClose={handleRuleCloseEdit}
            />
            <UpdateStatusMultiple
                onClose={() => { setOpenDialogEditMultiple(false) }}
                handleUpdateStatusMultiple={HandleUpdateStatusMultiple}
                openDialogEditMultiple={openDialogEditMultiple}
            />
        </div>
    );
}

export default Restructuring;