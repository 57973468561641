import React from "react";

import SelFilter from "./selFilter.component";
import UserService from "../services/user.service";
import AuthService from "../services/auth.service";

import * as constants from '../common/constants'

import { addParamToUrl, formatdate, removeParamFromUrl, getCurrentTimestamp } from "../common/functions";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import {
    DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector, GridToolbarQuickFilter
} from '@mui/x-data-grid';

import Pagination from '@mui/material/Pagination';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';


import { GrEdit, GrHistory } from 'react-icons/gr'

import { useSnackbar } from 'notistack';
import UpdateStatusMultiple from "./multiStatusUpdate.component";

import { RenderCellExpand, GridCellExpand } from "../common/renderCellExpand";

import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';

import { triggerBase64Download } from "../common/base64downloader";


function SelCrit() {

    const headerDisplayOptions = { none: 'none', flex: 'flex' }

    const { enqueueSnackbar } = useSnackbar();
    const [rowData, setRowData] = React.useState([]);
    const [selectedRowIDs, setSelectedRowIDs] = React.useState([]);
    const [selectedRowHistoryIDs, setSelectedRowHistoryIDs] = React.useState([]);
    const [hideHeaderText, setHideHeaderText] = React.useState(headerDisplayOptions.none);
    const [rowDataHistory, setRowDataHistory] = React.useState([]);
    const [rowToEdit, setRowToEdit] = React.useState([]);
    const [commentEdit, setCommentEdit] = React.useState('');
    const [descriptionEdit, setDescriptionEdit] = React.useState('');
    const [statusEdit, setStatusEdit] = React.useState('');
    const [openDialogEdit, setOpenDialogEdit] = React.useState(false);
    const [openDialogEditMultiple, setOpenDialogEditMultiple] = React.useState(false);
    const [openDialogHistory, setOpenDialogHistory] = React.useState(false);
    const [openDialogCompare, setOpenDialogCompare] = React.useState(false);
    const [compareL, setCompareL] = React.useState({});
    const [compareR, setCompareR] = React.useState({});

    // Add helper function to check if editing is frozen
    const isEditingFrozen = (status) => {
        return AuthService.getAppSetting(constants.SETTING_FREEZEEDITFORIMPLEMENTED)?.value === "true" 
            && (status === 'A' || status === 'I');
    };

    const showEditDialog = (row) => {
        addParamToUrl('editSelCrit', row.id);
        setCommentEdit(row.comment);
        setStatusEdit(row.status);
        setDescriptionEdit(row.description);
        setRowToEdit(row);
        setOpenDialogEdit(true);

    }

    const showHistoryDialog = (row) => {
        setRowDataHistory([]);
        addParamToUrl('showHistory', row.id);
        setRowToEdit(row);
        UserService.getSelCritHistory(row.id).then(
            response => {
                setRowDataHistory(response.data);
            },
            error => {
                setRowDataHistory([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );

        setOpenDialogHistory(true);

    }

    const handleCloseEdit = () => {
        removeParamFromUrl('editSelCrit');
        setOpenDialogEdit(false);
    };

    const handleCloseHistory = () => {
        removeParamFromUrl('showHistory');
        setOpenDialogHistory(false);
    };

    const SelectEditStatusCell = (props) => {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = async (event) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
            apiRef.current.stopCellEditMode({ id, field });
        };

        return (
            <Select
                value={value}
                onChange={handleChange}
                size="small"
                sx={{ height: 1, padding: 0, m: 0, fontSize: 14 }}
                native
            // autoFocus
            >
                {constants.STATUSES.map(option => {
                    return (
                        <option key={option.id} value={option.id}>
                            {option.val}
                        </option>
                    )
                })}
            </Select>
        );
    }

    const renderSelectEditStatusCell = (params) => {
        return <SelectEditStatusCell {...params} />;
    };

    const columns = [
        // { field: 'id', headerName: 'Id' },
        { field: 'id', headerName: 'Id' },
        {
            field: 'project', headerName: 'Project', width: '90',
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        { field: 'block', headerName: 'Block', width: AuthService.getAppSetting(constants.SETTING_COLWIDTHBLOCK)?.value ?? '60',
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        {
            field: 'table', headerName: 'Table', width: '200',
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        { field: 'layout', headerName: 'Layout', width: '70' },
        {
            field: 'status',
            headerName: 'Status',
            width: '110',
            editable: true,
            renderCell: (params) => {
                let status = "";
                status = constants.STATUSES.find(x => x.id === params.row.status)?.val;
                return status;
            },

            renderEditCell: renderSelectEditStatusCell
        },
        {
            field: 'description',
            headerName: 'Description',
            width: '300',
            editable: (params) => !isEditingFrozen(params.row.status),
            renderCell: RenderCellExpand
        },

        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            width: '80',
            renderCell: (params) => {
                const onClickEdit = (e) => {
                    e.stopPropagation(); // don't select this row after clicking


                    return showEditDialog(params.row);
                };

                const onClickHistory = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return showHistoryDialog(params.row);
                };

                return <span>
                    <IconButton sx={{ pt:0.1 }} size="small" onClick={onClickEdit}><GrEdit /></IconButton>
                    <IconButton sx={{ pt:0.1 }} size="small" onClick={onClickHistory}><GrHistory /></IconButton>
                </span>;
            },
        },
        {
            field: 'comment', headerName: 'Comment', width: '200', editable: (params) => !isEditingFrozen(params.row.status),
            renderCell: RenderCellExpand
        },
        {
            field: 'modifiedAt',
            headerName: 'Last change',
            width: '150',
            renderCell: (params) => {
                return formatdate(params.row.modifiedAt);
            }
        },
        { field: 'modfiedBy', headerName: 'User', width: '120' },

    ];

    const columnsHistory = [

        { field: 'id', headerName: 'Id' },
        { field: 'project', headerName: 'Project', width: '70' },
        { field: 'block', headerName: 'Block', width: AuthService.getAppSetting(constants.SETTING_COLWIDTHBLOCK)?.value ?? '60',
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        { field: 'table', headerName: 'Table', width: '190' },
        { field: 'layout', headerName: 'Layout', width: '70' },
        {
            field: 'status',
            headerName: 'Status',
            width: '110',
            renderCell: (params) => {
                let status = "";
                status = constants.STATUSES.find(x => x.id === params.row.status)?.val;
                return status;
            },
        },
        {
            field: 'description', headerName: 'Description', width: '250',
            renderCell: RenderCellExpand
        },
        {
            field: 'comment', headerName: 'Comment',
            renderCell: RenderCellExpand
        },
        {
            field: 'modifiedAt',
            headerName: 'Last change',
            width: '150',
            renderCell: (params) => {
                return formatdate(params.row.modifiedAt);
            }
        },
        { field: 'modfiedBy', headerName: 'User', width: '120' },

    ];

    const handleFilterUpdate = (value) => {
        localStorage.setItem('lastFilterSelCrit', JSON.stringify(value));

        UserService.getSelCritWithFilter(value).then(
            response => {
                setRowData(response.data);

                // show edit dialogs if param in url
                let url = new URL(window.location.href);
                let editSelCrit = url.searchParams.get("editSelCrit");
                if (editSelCrit) {
                    let row = response.data.find(x => x.id == editSelCrit);
                    if (row) {
                        showEditDialog(row);
                    } else {
                        removeParamFromUrl('editSelCrit');
                    }

                }

                let showHistory = url.searchParams.get("showHistory");
                if (showHistory) {
                    let rule = response.data.find(x => x.id == showHistory);
                    if (rule) {
                        showHistoryDialog(rule);
                    } else {
                        removeParamFromUrl('showHistory');
                    }

                }
            },
            error => {
                setRowData([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    };

    const handleExport = (value) => {

        UserService.downloadSelectinCriteriaData(value).then(
            response => {
                if (response.data.restructuringDataBase64) {
                    triggerBase64Download('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
                        + response.data.restructuringDataBase64, 'SelectionCriteria_' + getCurrentTimestamp());
                }
            },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    }

    const updateSelCrit = (data) => {
        let row = {
            ...data
        };
        const id = row.id;
        row.id = null;
        UserService.updateSelCrit(id, row).then(res => {

            let tmpFilter = JSON.parse(localStorage.getItem('lastFilterSelCrit'));
            //refresh restructuring after sucessfull update
            UserService.getSelCritWithFilter(tmpFilter).then(
                response => {
                    setRowData(response.data);

                    enqueueSnackbar('Successfully updated ' + row.table, { variant: 'success' });
                },
                error => {
                    setRowData([]);
                    const resMessage =
                        (error.response.data.errorMessage) ||
                        error.message ||
                        error.toString();
                    enqueueSnackbar(resMessage, { variant: 'error' });
                }
            )
        });
    }

    const handleSaveEdit = () => {
        let row = {
            ...rowToEdit
        };

        row.comment = commentEdit;
        row.status = statusEdit;
        row.description = descriptionEdit;
        updateSelCrit(row);
        setOpenDialogEdit(false);

    };

    function CustomFooterStatusComponent(props) {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (

            <Box sx={{ p: 1, display: 'flex', borderTop: 1, borderColor: '#ddd' }}>
                <Box sx={{ m: 0, flex: 1 }}>
                    {selectedRowIDs.length > 0 && AuthService.hasPermission(constants.PERMISSION_EDIT) ?
                        <Button
                            sx={{ m: 0 }}
                            variant="outlined"
                            onClick={() => { setOpenDialogEditMultiple(true) }}
                        >Set status for {selectedRowIDs.length} selected rows</Button>
                        : ""}
                </Box>

                <Box >
                    <Pagination
                        sx={{ height: 37 }} /* to prevent changing footer height on selected rows */
                        color="primary"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => apiRef.current.setPage(value - 1)}

                    />
                </Box>
            </Box>


        );
    }

    function CustomFooterHistoryStatusComponent(props) {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        // this seems thrown an error on console when opening the history - TODO: check why
        // setHideHeaderText(selectedRowHistoryIDs.length > 0 ? headerDisplayOptions.flex : headerDisplayOptions.none)

        return (

            <Box sx={{ p: 1, display: 'flex', borderTop: 1, borderColor: '#ddd' }}>
                <Box sx={{ m: 0, flex: 1 }}>

                    {selectedRowHistoryIDs.length == 2 ?
                        <Button
                            sx={{ m: 0 }}
                            variant="outlined"
                            onClick={() => {
                                setOpenDialogCompare(true)
                                let sortedHistory = selectedRowHistoryIDs.sort()
                                if (sortedHistory.length == 2) {
                                    // const getSelectedUser = (userId) => users.find(({id}) => id == userId)
                                    const leftId = sortedHistory[0]
                                    const getHistoryRecord = (leftId) => rowDataHistory.find(({ modifiedAt }) => modifiedAt == leftId)
                                    let leftHist = getHistoryRecord(sortedHistory[0])
                                    let rightHist = getHistoryRecord(sortedHistory[1])
                                    setCompareL(leftHist);
                                    setCompareR(rightHist);
                                } else {
                                    setCompareL({});
                                    setCompareR({});
                                }
                            }}
                        >Compare selected rows</Button>
                        : <Button
                            sx={{ m: 0 }}
                            variant="outlined"
                            disabled={true}
                        >Select 2 rows to compare</Button>}
                </Box>

                <Box >
                    <Pagination
                        sx={{ height: 37 }} /* to prevent changing footer height on selected rows */
                        color="primary"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => apiRef.current.setPage(value - 1)}

                    />
                </Box>
            </Box>


        );
    }

    React.useEffect(() => {
        // Runs after the first render() lifecycle

        //get last used filter for restructuring
        // THIS IS TRIGGERED FROM FILTER NOW
        // let tmp = JSON.parse(localStorage.getItem('lastFilterSelCrit'));
        // if (tmp !== undefined && tmp !== null) {
        //     UserService.getSelCritWithFilter(tmp).then(
        //         response => {
        //             setRowData(response.data);
        //         },
        //         error => {
        //             setRowData([]);
        //         }
        //     );
        // }

    }, []);

    const HandleUpdateStatusMultiple = (status) => {
        setOpenDialogEditMultiple(false);

        let updateData = {
            "status": status,
            "ids": selectedRowIDs
        }
        UserService.updateSelCritMultipleStatus(updateData).then(res => {
            let tmpFilter = JSON.parse(localStorage.getItem('lastFilterSelCrit'));
            //refresh after sucessfull update
            UserService.getSelCritWithFilter(tmpFilter).then(
                response => {
                    setRowData(response.data);
                    setSelectedRowIDs([]);
                    enqueueSnackbar('Successfully updated status', { variant: 'success' });
                },
                error => {
                    setRowData([]);
                    const resMessage =
                        (error.response.data.errorMessage) ||
                        error.message ||
                        error.toString();
                    enqueueSnackbar(resMessage, { variant: 'error' });
                }
            )
        },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    }

    function QuickSearchToolbar() {
        return (
            <Box
                sx={{
                    p: 0.5,
                    pb: 0,
                }}
            >
                <GridToolbarQuickFilter />
            </Box>
        );
    }

    // Modify the processRowUpdate to check frozen status
    const processRowUpdate = (newRow) => {
        const oldRow = rowData.find(row => row.id === newRow.id);
        
        // If editing is frozen, only allow status changes
        if (isEditingFrozen(oldRow.status)) {
            const allowedChanges = {
                ...oldRow,
                status: newRow.status
            };
            updateSelCrit(allowedChanges);
            return allowedChanges;
        }
        
        // Otherwise allow all changes
        updateSelCrit(newRow);
        return newRow;
    };

    return (
        <div style={{ height: '85vh', width: '100%' }}>
            <header className="">
                <h3>Selection criteria</h3>
                {/* <h3>{this.state.content}</h3> */}
                <SelFilter handleFilter={handleFilterUpdate} 
                    handleExport={handleExport}
                    showExport={true} />
            </header>
            <Box sx={{ height: '85vh', width: '100%' }}>
                <DataGrid
                    rows={rowData}
                    columns={columns}
                    columnVisibilityModel={{
                        id: false
                    }}
                    density="compact"
                    editMode="row"
                    processRowUpdate={processRowUpdate}
                    experimentalFeatures={{ newEditingApi: true }}
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowHeight={32}
                    getRowClassName={(params) => `gridRowColor-${params.row.status}`}
                    pagination
                    pageSize={100}
                    pageSizeOptions={[100]}
                    slots={{
                        footer: CustomFooterStatusComponent,
                        toolbar: QuickSearchToolbar
                    }}
                    rowSelectionModel={selectedRowIDs}
                    onRowSelectionModelChange={(ids, details) => {
                        setSelectedRowIDs(ids);
                    }}
                    sx={{
                        '.MuiDataGrid-cell--editing select': { pl: 1, pb: 0, pt: 0 },
                        '.MuiDataGrid-cell--editing input': { p: 1 }
                    }}
                // onRowEditCommit={(params) => {
                //     console.log(params);
                //     updateSelCrit(params.row);
                // }}
                />
            </Box>
            <Dialog
                open={openDialogEdit}
                onClose={handleCloseEdit}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>Edit {rowToEdit.table}<br />
                    <small>{rowToEdit.project} / {rowToEdit.block}</small></DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>

                    <FormControl sx={{ m: 1, width: 200 }} >
                        <InputLabel id="statusEdit-label">Status</InputLabel>
                        <Select
                            labelId="statusEdit-label"
                            id="statudEdit"
                            value={statusEdit}
                            onChange={event => setStatusEdit(event.target.value)}
                            input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                        // MenuProps={MenuProps}
                        >
                            {constants.STATUSES.map(option => {
                                return (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.val}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <br />
                    <FormControl sx={{ m: 1, width: 800 }}>
                        <TextField 
                            id="outlined-basic"
                            label="Description"
                            variant="outlined"
                            multiline
                            maxRows={16}
                            defaultValue={descriptionEdit}
                            onChange={event => setDescriptionEdit(event.target.value)}
                            disabled={isEditingFrozen(rowToEdit.status)}
                        />
                    </FormControl>
                    <br />
                    <FormControl sx={{ m: 1, width: 800 }}>
                        <TextField 
                            id="outlined-basic"
                            label="Comment"
                            variant="outlined"
                            multiline
                            maxRows={4}
                            defaultValue={commentEdit}
                            onChange={event => setCommentEdit(event.target.value)}
                            disabled={isEditingFrozen(rowToEdit.status)}
                        />
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEdit}>Cancel</Button>
                    {AuthService.hasPermission(constants.PERMISSION_EDIT) && (
                        <Button 
                            onClick={handleSaveEdit}
                            disabled={isEditingFrozen(rowToEdit.status) && statusEdit === rowToEdit.status}
                        >
                            Save
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDialogCompare}
                onClose={() => { setOpenDialogCompare(false) }}
                maxWidth="false"
            >
                <DialogTitle>History compare</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>

                        <ReactDiffViewer
                            oldValue={compareL.description ?? ""}
                            newValue={compareR.description ?? ""}
                            leftTitle={"Description - " + formatdate(compareL.modifiedAt ?? "")}
                            rightTitle={"Description - " + formatdate(compareR.modifiedAt ?? "")}
                            splitView={true}
                            showDiffOnly={false}
                            compareMethod={DiffMethod.WORDS} />
                        <br />
                        <ReactDiffViewer
                            oldValue={compareL.comment ?? ""}
                            newValue={compareR.comment ?? ""}
                            leftTitle={"Comment - " + formatdate(compareL.modifiedAt ?? "")}
                            rightTitle={"Comment - " + formatdate(compareR.modifiedAt ?? "")}
                            splitView={true}
                            showDiffOnly={false} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenDialogCompare(false) }}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDialogHistory}
                onClose={handleCloseHistory}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle>History for {rowToEdit.table}</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <div style={{ height: '400px' }}>
                        <DataGrid
                            rows={rowDataHistory}
                            columns={columnsHistory}
                            columnVisibilityModel={{
                                id: false
                            }}
                            density="compact"
                            // disableRowSelectionOnClick
                            rowHeight={32}
                            getRowClassName={(params) => `gridRowColor-${params.row.status}`}
                            getRowId={(row) => row.modifiedAt}
                            checkboxSelection
                            slots={{
                                footer: CustomFooterHistoryStatusComponent,
                                toolbar: QuickSearchToolbar
                            }}
                            rowSelectionModel={selectedRowHistoryIDs}
                            onRowSelectionModelChange={(ids, details) => {
                                setSelectedRowHistoryIDs(ids);
                            }}
                            sx={{
                                '.MuiDataGrid-cell--editing select': { pl: 1, pb: 0, pt: 0 },
                                '.MuiDataGrid-cell--editing input': { p: 1 },
                                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                    // display: "flex"
                                    display: headerDisplayOptions[hideHeaderText] ?? "flex"
                                }
                            }}
                        />
                    </div>
                    <FormControl>

                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseHistory}>Close</Button>
                </DialogActions>
            </Dialog>

            <UpdateStatusMultiple
                onClose={() => { setOpenDialogEditMultiple(false) }}
                handleUpdateStatusMultiple={HandleUpdateStatusMultiple}
                openDialogEditMultiple={openDialogEditMultiple}
            />
        </div>
    );
}

export default SelCrit;