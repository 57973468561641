import axios from 'axios';
import AuthService from "./auth.service";

const API_URL = process.env.REACT_APP_API_URL;

let api = axios.create({
    withCredentials: true // to make sure the JSESSIONID cookie is sent with each request
});

api.interceptors.request.use(function (config) {

    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    document.body.classList.add('loading-indicator');
    return config
}, function (error) {
    return Promise.reject(error);
});

api.interceptors.response.use((response) => {

    document.body.classList.remove('loading-indicator');
    
    let respData = "";
    if(response.data) {
        respData = JSON.stringify(response.data);
    }
    let isString = (typeof respData === 'string' || respData instanceof String);
    if (response && ((response.data && isString && (respData.includes("Issues with the session") || respData.includes("Invalid login"))) || (response.status === 401))) {
        AuthService.logout();
        window.location.href = '/login';
    } else {
        return response;
    }
}, (error) => {

    document.body.classList.remove('loading-indicator');
    let respData = "";
    if(error.response.data) {
        respData = JSON.stringify(error.response.data);
    }

    let isString = (typeof respData === 'string' || respData instanceof String);

    if (error.response && ((error.response.data && isString && (respData.includes("Issues with the session") || respData.includes("Invalid login"))) || (error.response.status === 401))) {
        AuthService.logout();
        window.location.href = '/login';
    }
    throw error;
});

function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user === null) {
        AuthService.logout();
        window.location.href = '/login';
    }
    if (user && user.token) {
        return { Authentication: 'Bearer:' + user.token };
    } else {
        return {};
    }
}
class UserService {

    getFilterOptions(filter) {
        const options = {
            headers: authHeader()
        };
        return api.post(API_URL + 'common/getFilterOptions', filter, options);
    }

    getProjectsAndBlocks() {

        return api.get(API_URL + 'common/getProjectsAndBlocks', { headers: authHeader() });
    }

    createProject(data) {

        return api.put(API_URL + 'common/createProject', data, { headers: authHeader() });
    }

    createBlock(data) {

        return api.put(API_URL + 'common/createBlock', data, { headers: authHeader() });
    }

    getRestructuringWithFilter(filter) {
        const options = {
            headers: authHeader()
        };
        return api.post(API_URL + 'restructuring', filter, options);
    }

    downloadRestructuringData(filter) {
        return api.post(API_URL + 'restructuring/download', filter, { headers: authHeader() }).then(response => {
            return response;
        });
    }

    downloadSelectinCriteriaData(filter) {
        return api.post(API_URL + 'selectioncriterias/download', filter, { headers: authHeader() }).then(response => {
            return response;
        });
    }

    getSelCritWithFilter(filter) {
        const options = {
            headers: authHeader()
        };
        return api.post(API_URL + 'selectioncriterias', filter, options);
    }

    getRulesFilter(filter) {
        const options = {
            headers: authHeader()
        };
        return api.post(API_URL + 'rule', filter, options);
    }

    getRestructuringHistory(id) {
        const options = {
            headers: authHeader()
        };
        return api.get(API_URL + 'restructuring/history/' + id, options);
    }

    getRule(id) {
        const options = {
            headers: authHeader()
        };
        return api.get(API_URL + 'rule/' + id, options);
    }

    getRuleHistory(id) {
        const options = {
            headers: authHeader()
        };
        return api.get(API_URL + 'rule/history/' + id, options);
    }

    getSelCritHistory(id) {
        const options = {
            headers: authHeader()
        };
        return api.get(API_URL + 'selectioncriterias/history/' + id, options);
    }

    updateRestructuring(id, rest) {
        const options = {
            headers: authHeader()
        };
        return api.put(API_URL + 'restructuring/' + id, rest, options).then(response => {
            return response;
        });
    }

    updateRestructuringMultipleStatus(data) {
        return api.put(API_URL + 'restructuring/updateStatusForMultiple', data, { headers: authHeader() }).then(response => {
            return response;
        });
    }

    updateSelCrit(id, rest) {
        const options = {
            headers: authHeader()
        };
        return api.put(API_URL + 'selectioncriterias/' + id, rest, options).then(response => {
            return response;
        });
    }

    updateSelCritMultipleStatus(data) {
        return api.put(API_URL + 'selectioncriterias/updateStatusForMultiple', data, { headers: authHeader() }).then(response => {
            return response;
        });
    }

    updateRule(id, rest) {
        const options = {
            headers: authHeader()
        };
        return api.put(API_URL + 'rule/' + id, rest, options).then(response => {
            return response;
        });
    }

    updateRuleMultipleStatus(data) {
        return api.put(API_URL + 'rule/updateStatusForMultiple', data, { headers: authHeader() }).then(response => {
            return response;
        });
    }

    createRuleTable(id, params) {
        const options = {
            headers: authHeader()
        };
        return api.put(API_URL + 'rule/' + id + '/createTable', params, options).then(response => {
            return response;
        });
    }

    getRuleData(id) {
        return api.get(API_URL + 'rule/' + id + '/getRuleData', { headers: authHeader() }).then(response => {
            return response;
        });
    }

    downloadRuleData(id) {
        return api.get(API_URL + 'rule/' + id + '/downloadRuleData', { headers: authHeader() }).then(response => {
            return response;
        });
    }

    uploadRuleData(id, data) {
        return api.put(API_URL + 'rule/' + id + '/uploadRuleData', data, { headers: authHeader() }).then(response => {
            return response;
        });
    }

    updateRuleData(id, params) {
        return api.put(API_URL + 'rule/' + id + '/updateRuleData', params, { headers: authHeader() }).then(response => {
            return response;
        });
    }

    createRule(val) {
        return api.put(API_URL + 'rule', val, { headers: authHeader() }).then(response => {
            return response;
        });
    }

    createUser(val) {
        return api.put(API_URL + 'userrole/user', val, { headers: authHeader() }).then(response => {
            return response;
        });
    }

    updateUser(id, val) {
        return api.put(API_URL + 'userrole/user/' + id, val, { headers: authHeader() }).then(response => {
            return response;
        });
    }

    deleteUser(id, val) {
        return api.delete(API_URL + 'userrole/user/' + id, { headers: authHeader() }).then(response => {
            return response;
        });
    }

    updateAppSetting(id, val) {
        return api.put(API_URL + 'settings/' + id, val, { headers: authHeader() }).then(response => {
            return response;
        });
    }

    getAllRules() {
        const options = {
            headers: authHeader()
        };
        return api.post(API_URL + 'rule', {
            "or": [
                {
                    "and": [
                        {
                            "field": "project",
                            "expressionType": "like",
                            "value": "%"

                        }
                    ]
                }
            ]
        }, options);
    }

    getAllUsers() {
        const options = {
            headers: authHeader()
        };
        return api.post(API_URL + 'userrole/user', {
            "or": [
                {
                    "and": [
                        {
                            "field": "login",
                            "expressionType": "like",
                            "value": "%"
                        }
                    ]
                }
            ]
        }, options);
    }

    getUser(id) {
        return api.get(API_URL + 'userrole/user/' + id, { headers: authHeader() });
    }

    getAllRoles() {
        const options = {
            headers: authHeader()
        };
        return api.post(API_URL + 'userrole/role', {
            "or": [
                {
                    "and": [
                        {
                            "field": "role",
                            "expressionType": "like",
                            "value": "%"
                        }
                    ]
                }
            ]
        }, options);
    }

    getRestructuring() {
        const options = {
            headers: authHeader()
        };
        return api.post(API_URL + 'restructuring', {
            "or": [
                {
                    "and": [
                        {
                            "field": "tableName",
                            "expressionType": "like",
                            "value": "CRS610MI%"

                        }
                    ]
                }
            ]
        }, options);
    }

    getRestructuringSummary() {
        const options = {
            headers: authHeader()
        };
        return api.get(API_URL + 'restructuring/summary/all', options);
    }

    getSelCritSummary() {
        const options = {
            headers: authHeader()
        };
        return api.get(API_URL + 'selectioncriterias/summary/all', options);
    }

    copySelectionCriteria(val) {
        return api.put(API_URL + 'maintain/copySelectionCriteria', val, { headers: authHeader() });
    }

    copyRestructuring(val) {
        return api.put(API_URL + 'maintain/copyRestructuring', val, { headers: authHeader() });
    }


    createDefinition(val) {
        return api.put(API_URL + 'maintain/createDefinitionsFromM3Api', val, { headers: authHeader() });
    }

    getUserBoard() {
        return api.get(API_URL + 'user', { headers: authHeader() });
    }

    getModeratorBoard() {
        return api.get(API_URL + 'mod', { headers: authHeader() });
    }

    getAdminBoard() {
        return api.get(API_URL + 'admin', { headers: authHeader() });
    }

    getAllAppSettings() {
        return api.get(API_URL + 'settings/getAppSettings', { headers: authHeader() });
    }

    getBlockSummary() {
        return axios.get(API_URL + "restructuring/summary/block", { headers: authHeader() });
    }

    getXrefLogs(filter) {
        if (filter == undefined) {
            filter = {
                "or": [
                    {
                        "and": [
                            {
                                "field": "project",
                                "expressionType": "like",
                                "value": "%"
                            }
                        ]
                    }
                ]
            };
        }
        return api.post(API_URL + 'logs/xrefLogs', filter, { headers: authHeader() });
    }
}

export default new UserService();